<template>
  <div class="content-box">
    <div class="content">
      <div class="search-action">
        <div class="inputs_filter">
          <el-input
            placeholder="请输入关键字"
            prefix-icon="el-icon-search"
            class="search-input"
            v-model="search"
            clearable
            @clear="getList"
          >
          </el-input>
          <div class="search-btn" @click="getList">搜索</div>
        </div>
      </div>

      <div class="recommend">
        <div class="recommend-title">
          <span class="big-title">为你推荐</span>
        </div>
        <div class="wrap-recommends">
          <ul class="recommend-list">
            <li
              class="recommend-item"
              v-for="(item, index) in recommendList"
              :key="index"
              @click="xq(item.id)"
            >
              <div class="expert-link">
                <div class="cover">
                  <img v-if="item.img" :src="urlImg + item.img" alt="" />
                  <img
                    v-else
                    src="../../../assets/img/huiyuanzhongxin/default_error.png"
                    alt=""
                  />
                </div>
                <div class="detail">
                  <div class="name">{{ item.name }}</div>
                  <div class="desc">
                    {{ item.project_say }}
                  </div>
                  <div class="news-time-addr-cate">
                    <div></div>
                    <div class="label right">
                      <span class="cate">{{
                        item.territory ? item.territory.name : ""
                      }}</span
                      ><span class="types">{{
                        item.maturity ? item.maturity.name : ""
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <pagination
            class="pages"
            v-show="total > 0"
            :total="total"
            layout="prev, pager, next"
            :page.sync="listQuery.page"
            :limit.sync="listQuery.limit"
            @pagination="getList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { achievementsLists } from "../../../common/js/api";
import Pagination from "@/common/Pagination";
import { urlImg } from "@/common/js/url";

export default {
  components: { Pagination },
  data() {
    return {
      recommendList: [],
      total: 0, //总条目数
      listQuery: {
        limit: 4,
        page: 1,
      },
      search: "",
      urlImg: "",
    };
  },
  created() {
    this.search = this.$route.query.keyword;
    this.getList();
    this.urlImg = urlImg();
  },
  methods: {
    getList() {
      achievementsLists({
        name: this.search,
        page: this.listQuery.page,
        limits: this.listQuery.limit,
      })
        .then((res) => {
          console.log(res);
          this.recommendList = res.data.data;
          this.total = res.data.total;
        })
        .catch(() => {});
    },
    // 成果详情
    xq(id) {
      let routeData =  this.$router.resolve({
        path: "/Achievement_details",
        query: {
          id: id,
        },
      });
       window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .search-action {
    margin: 30px 30% 16px 20%;
  }
  /deep/.el-input__inner {
    border: 0;
  }
  .recommend {
    border: 1px solid #bbb;
    background-color: #fff;
    .recommend-list {
      cursor: pointer;
      .recommend-item:nth-last-child(1) {
        border-bottom: 0;
      }
      .recommend-item:hover .expert-link .detail .name {
        color: #eeb47f;
      }

      .recommend-item {
        padding-top: 15px;
        padding-bottom: 16px;
        border-bottom: 1px solid #bbb;
        .expert-link {
          display: flex;
          text-align: left;
          color: #696969;
          .cover {
            margin-right: 24px;
            width: 239px;
            height: 130px;
          }
          .detail {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 14px;
            color: #696969;
            .name {
              color: #101010;
              font-size: 16px;
              font-weight: 600;
            }
            .desc {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              max-height: 52px;
              line-height: 1.2;
            }
            .news-time-addr-cate {
              .label {
                color: #ffb23b;
                border-radius: 3px;
                .cate {
                  padding: 3px 10px;
                  margin-right: 22px;
                  border: 1px solid #ffb23b;
                  border-radius: 5px;
                }
                .types {
                  padding: 3px 10px;
                  color: #2a71d2;
                  border: 1px solid #2a71d2;
                  border-radius: 5px;
                }
              }
            }
          }
        }
      }
    }
    .pagination {
      text-align: center;
    }
  }
}
</style>